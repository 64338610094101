window.app.service('API', ['$resource', '$http', '$filter', 'Config', 'ErrFactory',
  function($resource, $http, $filter, Config, ErrFactory) {
  return {
    getMe() {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/me`,
        method: 'GET'
      }))
      .then((res) => res.data);
    },

    get Users () {
      return $resource(`${Config.hostApi}users/:action`, {action: '@action'}, {
        acceptPrivacyPolicy: {
          method: 'POST',
          params: {
            action: 'accept_privacy_policy.json'
          }
        }
      });
    },

    restorePassword(data) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/passwords/send_reset_instructions`,
        method: 'POST',
        data
      }));
    },

    restorePasswordByToken(data) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/passwords/reset_by_token`,
        method: 'POST',
        data
      }));
    },

    get TimeTracking () {
      return $resource(`${Config.host}/time_tracking/api/:apiVersion/:action`, {action:'@action'}, {
        sendActivityReports : {
          method: 'POST',
          isArray: true,
          params: {
            action: "working_periods",
            apiVersion: 'v3'
          }
        }
      });
    },

    workingPeriodsPaginated(page, column, dir) {
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/working_periods?per_page=10&page=${page}${sort}`,
        method: 'GET'
      }))
      .then((res) => res.data);
    },

    workingPeriodById(id) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/working_periods/${id}`,
        method: 'GET'
      }))
      .then((res) => res.data);
    },

    get PushConfirm () {
      return $resource(`${Config.host}/api/mobile/v3/push_notifications/:id/mark_read`, {id:'@id'}, {
        send: { method: "PUT" }
      });
    },

    getPushNotifications(status, triggers, page = 1) {
      let statusQuery   = `q[recipients][status]=${status}`;
      let triggersQuery = triggers.map(t => `q[triggers][trigger_types][]=${t}`).join('&');

      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/push_notifications?${statusQuery}&${triggersQuery}&page=${page}&per_page=200`,
      })).then((r) => r.data);
    },

    getCampaignBanner() {
      return Promise.resolve($http({
        method: 'GET',
        url: Config.hostApi + 'mobile_app_images.json',
      })).then((r) => r.data);
    },

    getAssignments(page = 1, perPage = 250) {
      let validFrom = Weeks.getStartOfWeek(new Date(new Date().getTime() - 7*7*24*60*60*1000));
      let validTo   = Weeks.getEndOfWeek(  new Date(new Date()));
      validFrom     = $filter('date')(validFrom, 'yyyy-MM-dd');
      validTo       = $filter('date')(validTo,   'yyyy-MM-dd');

      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/assignments?page=${page}&per_page=${perPage}&q[assignments][active]=true&q[assignments][date_from]=${validFrom}&q[assignments][date_to]=${validTo}&order_field=starts_at&order_direction=desc`
      }))
      .then((res) => res && res.data);
    },

    getBetaAssignments(betaFeature) {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/assignments?order_field=starts_at&order_direction=desc&q[beta_locations][feature]=${betaFeature}`
      }))
      .then((res) => res?.data);
    },

    getValidMileageDays() {
      let validFrom = Weeks.getStartOfWeek(new Date(new Date().getTime() - 7*7*24*60*60*1000));
      let validTo   = Weeks.getEndOfWeek(  new Date(new Date()));
      validFrom     = $filter('date')(validFrom, 'yyyy-MM-dd');
      validTo       = $filter('date')(validTo,   'yyyy-MM-dd');

      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/assignments/mileage_money?q[assignments][active]=true` +
             `&q[mileage_money][starts_on_lteq]=${validTo}&q[mileage_money][ends_on_gteq]=${validFrom}`  +
             `&q[assignments][starts_on_lteq]=${validTo}&q[assignments][ends_on_gteq]=${validFrom}`      +
             `&order_field=starts_at&order_direction=desc`
      }))
      .then((res) => res && res.data);
    },

    updateLicensePlate(params) {
      return Promise.resolve($http({
        method: 'POST',
        url: `${Config.host}/time_tracking/api/v2/user_license_plates.json`,
        data: JSON.stringify(params)
      }))
      .then((res) => console.log(res));
    },

    createReferralProgramReferee(params) {
      return Promise.resolve($http({
        method: 'POST',
        url: `${Config.host}/time_tracking/api/v3/referral_program_referees.json`,
        data: JSON.stringify(params)
      }))
      .then((res) => console.log(res));
    },

    getCompanyBanners() {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/v2/company_banners`,
      }))
      .then((res) => res.data);
    },

    getCompanyBanner(imagePath, etag = '') {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}${imagePath}`,
        responseType: "arraybuffer",
        headers: {
          'If-None-Match': etag
        }
      }))
      .then((res) => ({
        etag: res.headers("etag"),
        banner: res.data
      }))
      .catch((err) => {
        if (err.response.status === 304) return Promise.resolve();
        return Promise.reject(err)
      });
    },

    getTutorials() {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v2/tutorials_list`
      }))
      .then((res) => res.data);
    },

    sendToElasticSearch (log) {
      var currentDate = new Date();
      var month = currentDate.getMonth() + 1;
      var monthString = `${month < 10 ? '0' : ''}${month}`;
      var indexName = `applications_${currentDate.getFullYear()}_${monthString}`;
      return $http({
        method: 'POST',
        url: `${Config.api.elasticsearch.path}/${indexName}/properties`,
        data: log,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(Config.api.elasticsearch.credentials)
        }
      })
      .then(function(rsp) {
        return log;
      });
    },

    get getDocumentTypes () {
      return $resource(`${Config.host}/time_tracking/api/v2/document_types`, {}, {
        list: {
          method: "GET"
        }
      });
    },

    getPhotoDocumentsList() {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/photo_documents`
      }))
      .then((res) => res && res.data && res.data.photo_documents || [])
      .catch((err) => console.error(err));
    },

    getPhotoDocumentsListPaginated(page, state, column, dir) {
      let filter = state ? `&q[photo_documents][${state}]=1` : '';
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/photo_documents?per_page=10&page=${page}${filter}${sort}`
      }))
      .then((res) => res.data)
      .catch((err) => console.error(err));
    },

    createPhotoDocument(params) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/photo_documents`,
        method: 'POST',
        data: params,
      }))  
      .then((res) => res.data)
      .catch((err) => {
        if (err instanceof ErrFactory) throw err;
        else throw new ErrFactory('errors.remoteServer')
      });
    },

    getRandomImage(w, h) {
      return Promise.resolve($http({
        url: `https://picsum.photos/${w}/${h}.jpg`,
        method: 'GET',
        responseType: 'arraybuffer'
      }))
      .then((response) => {
        let image = btoa(
          new Uint8Array(response.data)
            .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return `data:image/jpeg;base64,${image}`;
      });
    },

    getLatestEBS(assignment_id) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/assignments/${assignment_id}/ebs_data/latest.json`,
        method: 'GET'
      }))
      .then((res) => res.data.last_ebs_version);
    },

    confirmEBS(ebs_id) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/ebs_data/${ebs_id}/confirm`,
        method: 'PUT'
      }));
    },

    getAllEBS(page = 1, perPage = 250) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/ebs_data?per_page=${perPage}&page=${page}`,
        method: 'GET'
      }))
      .then((res) => res.data);
    },

    signInV3 (params) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/sign_in`,
        method: 'POST',
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        },
        data: params
      }))
      .then((res) => res.data.refresh_token);
    },

    signOutV3() {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/sign_out`,
        method: 'DELETE',
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json'
        }
      }))
    },

    refreshAccessToken(token) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/refresh`,
        method: 'POST',
        data: {
          "refresh_token": token
        },
      }))
      .then((res) => res.data && res.data.refresh_token);
    },

    sendSupportRequestWithDeviceInfo(params) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v2/support_requests`,
        method: 'POST',
        data: {
          "support_request": params
        }
      }));
    },

    getCurrentStoreVesrion(platform, appName) {
      return Promise.resolve($http({
        url: Config.host + '/api/v2/mobile_app_releases/version' +
              '?mobile_app_release[platform]=' + platform +
              '&mobile_app_release[app_name]=' + appName +
              '&mobile_app_release[country_code]=de',
        method: 'GET'
      }))
      .then((res) => {
        if (res.data) {
          localStorage.setItem('lastTimeCheckedVersion', +new Date() + '');
          localStorage.setItem('storeUrl', res.data.store_url);
          return res.data
        }
      });
    },

    getActivationCodeV3(params) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/activations/send_code`,
        method: 'POST',
        data: params
      }));
    },

    activateAccountV3(params) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/activations/activate`,
        method: 'POST',
        data: params
      }));
    },

    setPasswordV3(params) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v3/users/activations/set_password`,
        method: 'POST',
        data: params
      }));
    },

    getHolidays(from, to) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/holidays?q[date][date_gteq]=${from}&q[date][date_lteq]=${to}`,
        method: 'GET',
      }))
      .then((res) => res.data.holidays);
    },

    getSalaryReports() {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/salary_reports`,
      }))
      .then((res) => res.data);
    },

    getSalaryReportsPaginated(page, column, dir) {
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      let srTwoFactor = localStorage.getItem('salaryReportsTwoFactorToken');
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/salary_reports?per_page=10&page=${page}${sort}`,
        method: 'GET',
        headers: {
          'Two-Factor-Access-Token': srTwoFactor
        }

      }))
      .then((res) => res && res.data);
    },

    getSalaryReportsById(reportId) {
      let srTwoFactor = localStorage.getItem('salaryReportsTwoFactorToken');
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/salary_reports/${reportId}`,
        headers: {
          'Two-Factor-Access-Token': srTwoFactor
        }
      }))
      .then((res) => res.data);
    },

    requestSalaryReports2FA() {
      return Promise.resolve($http({
        url: `${Config.host}/api/v2/two_factor_authentications`,
        method: 'POST'
      }));
    },

    createSalaryReportsToken(birth) {
      return Promise.resolve($http({
        url: `${Config.host}/api/v2/two_factor_authentications/create_access_token`,
        method: 'POST',
        data: {
          two_factor_authentication_otp_code: birth
        }
      }))
      .then((res) => res && res.data && res.data.two_factor_authentication_access_token);
    },

    saveFcmToken(token) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/push_notification_targets`,
        method: 'PUT',
        data: {
          device_fcm_token: token
        }
      }))
      .then((res) => {
        if (res && res.data && res.data.push_notifications_targets) return res.data.push_notifications_targets.device_fcm_token;
      });
    },

    getSavedBackendLocale() {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/locales`,
      }))
      .then((res) => res && res.data && res.data.time_tracking_user && res.data.time_tracking_user.locale);
    },

    saveLocaleToBackend(lang) {
      return Promise.resolve($http({
        url: `${Config.host}/time_tracking/api/v3/locales`,
        method: 'PUT',
        data: {
          time_tracking_user: {
            locale: lang
          }
        }
      }));
    },

    getCustomerEmails(assignmentsId, page = 1) {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/customers?q[assignments][id]=${assignmentsId}&per_page=10&page=${page}`,
      }))
      .then((res) => res && res.data);
    },

    getReleases(page = 1) {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/release_notes?per_page=10&page=${page}&q[sort][0][pos]=0&q[sort][0][name]=released_at&q[sort][0][dir]=desc&q[sort][1][pos]=1&q[sort][1][name]=version&q[sort][1][dir]=desc`
      }))
      .then((res) => res && res.data);
    },

    getPN(page = 1, column, dir) {
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/time_tracking/api/v3/employee_notifications/dashboard?per_page=10&page=${page}${sort}`
      }))
      .then((res) => res && res.data);
    },

    submitVacationRequest(data) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/vacation_requests`,
        method: 'POST',
        data,
      }))  
      .then((res) => res.data);
    },

    getVacationRequests(page = 1, column, dir, state) {
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}${state}`;
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/vacation_requests?per_page=10&page=${page}${sort}`
      }))
      .then((res) => res && res.data);
    },
    
    getOpenVacationRequests() {
      let sort  = `&q[sort][0][pos]=0&q[sort][0][name]=created_at&q[sort][0][dir]=desc`;
      let query = `&q[vacation_requests][creator]=Internal Employee&q[vacation_requests][state]=awaiting_external_approval`
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/vacation_requests?per_page=100&page=1${sort}${query}`
      }))
      .then(res => res?.data?.vacation_requests);
    },

    getVacationRequestById(id: number) {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/vacation_requests/${id}`
      }))
      .then(res => res?.data?.vacation_request);
    },

    updateVacationRequest(id: number, data: any): Promise<any> {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/vacation_requests/${id}/external_review`,
        method: 'PUT',
        data
      }));
    },

    loadMileageMoneyReports(page = 1, column, dir, filter) {
      let sort  = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      let state = filter ? `&q[external_dashboard][states]=${filter}` : '';
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}/api/mobile/v3/standalone_mileage_reports?per_page=10&page=${page}${sort}${state}`
      }))
      .then((res) => res && res.data);
    },

    loadStandaloneMileageReport(startDate: any, endDate: any, assignmentId): Promise<any> {
      let start      = `standalone_mileage_report[start_date]=${$filter('date')(startDate, 'yyyy-MM-dd')}`;
      let end        = `standalone_mileage_report[end_date]=${  $filter('date')(endDate,   'yyyy-MM-dd')}`;
      let assignment = `standalone_mileage_report[assignment_id]=${assignmentId}`;
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/standalone_mileage_reports/new?${start}&${end}&${assignment}`,
        method: 'GET'
      }))
      .then(res => res?.data?.standalone_mileage_report);
    },

    submitMileageMoney(params) {
      let data = { standalone_mileage_report: params };
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/standalone_mileage_reports`,
        method: 'POST',
        data
      }))  
      .then(res => res?.data)
      .then(res => res?.standalone_mileage_report);
    },

    getMileageMoneyById(id) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/standalone_mileage_reports/${id}`,
        method: 'GET'
      }))
      .then((res) => res.data);
    },

    postFeedback(data) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/feedbacks`,
        method: 'POST',
        data: data
      }));
    },

    loadDevices(column, dir, page = 1) {
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
      return Promise.resolve($http({
          url: `${Config.host}/api/mobile/v3/user_devices?per_page=10&page=${page}&${sort}`,
          method: 'GET',
      })).then(res => {
          return res?.data;
      });
    },

    lockDevice(deviceId : string, lock : boolean) {
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/user_devices/${deviceId}`,
        method: 'PUT',
        data: { user_device: { lock: lock } }
      })).then(res => {
        return res?.data ;
      });
    },

    loadMyScore(){
        return Promise.resolve($http({
            url: `${Config.host}/api/mobile/v3/scores/my`,
            method: 'GET',
        })).then(res => {
            return res?.data?.score ;
        });
    },

    loadRewards(){
      return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/rewards`,
        method: 'GET',
      })).then(res => {
        return res?.data ;
      });
    },

    loadScores(column, dir, page = 1){
      let sort = `&q[sort][0][pos]=0&q[sort][0][name]=${column}&q[sort][0][dir]=${dir ? 'asc' : 'desc'}`;
       return Promise.resolve($http({
        url: `${Config.host}/api/mobile/v3/scores?per_page=5&page=${page}${sort}`,
        method: 'GET',
      })).then(res => {
        return res?.data ;
      });
    },

    loadAsset(imagePath) {
      return Promise.resolve($http({
        method: 'GET',
        url: `${Config.host}${imagePath}`,
        responseType: "arraybuffer",
      }))
          .then((res) => ({
            asset: res.data
          }))
          .catch((err) => {
            if (err.response.status === 304) return Promise.resolve();
            return Promise.reject(err)
          });
    },

  }
}]);
